import { Directive, ElementRef, HostListener, Inject, Input, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[open-browser-if-app]',
    standalone: true,
})
export class OpenBrowserIfAppDirective {
    @HostListener('click', ['$event']) onClick(e) {
        try {
            if (ExecuteSharingHook) {
                ExecuteSharingHook(JSON.stringify({
                    link: e.currentTarget.href,
                    size: ""
                }));
            }
        }
        catch { }
    }

    constructor() {

    }
}